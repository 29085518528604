import React, { useState } from "react";

function WeServe() {
  const [activeFaq, setActiveFaq] = useState([false, false, false, false]);

  const faqList = [
    {
      title: "Project Developers",
      content:
        "We help renewable energy project developers succeed, by providing them with early to late stage financing solutions tailored to their needs.",
    },
    {
      title: "Banks",
      content:
        "We collaborate closely with our affiliated banks in order to finance or restructure electricity generation asset portfolios.",
    },
    {
      title: "Investment Funds",
      content:
        "In some cases, we act as a fiduciary for other investors. In other cases, we supply high quality and efficiently structured energy production asset portfolios to fund managers.",
    },
    {
      title: "Governments",
      content:
        "We collaborate closely with governments in order to enable positive community projects, and we ensure that our activities fully align with regional government goals.",
    },
  ];

  const handleAccordionClick = (index) => {
    setActiveFaq(activeFaq.map((value, i) => (i === index ? !value : value)));
  };

  return (
    <>
      <section className="faq-one">
        <div
          className="faq-one__bg"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/images/backgrounds/trust-bg.png"
            })`,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="faq-one__content">
                <div className="block-title text-left">
                  <p>Who We Serve</p>
                  <h2>Energy & Investment Professionals</h2>
                </div>
                <p>
                  We collaborate with a wide variety of institutions that all
                  have in common the objective of implementing safe, resilient
                  and sustainable energy generation solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <ul
                id="accordion"
                className="mb-0 wow fadeInUp list-unstyled"
                data-wow-duration="1500ms"
              >
                {faqList.map((faq, index) => (
                  <li key={index}>
                    <h2
                      className={`para-title ${activeFaq[index] && "active"}`}
                      onClick={() => handleAccordionClick(index)}
                    >
                      <span
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        data-target={`#collapse-${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${index}`}
                      >
                        {activeFaq[index] ? (
                          <i className="far fa-minus"></i>
                        ) : (
                          <i className="far fa-plus"></i>
                        )}
                        {faq.title}
                      </span>
                    </h2>
                    <div
                      id={`collapse-${index}`}
                      className={`collapse ${activeFaq[index] ? "show" : ""}`}
                      aria-labelledby={`collapse-${index}`}
                      data-parent="#accordion"
                    >
                      <p>{faq.content}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WeServe;
