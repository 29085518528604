import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import React from "react";
import { Navigation, Autoplay } from "swiper";

function Slider() {
  return (
    <>
      <section className="main-slider main-slider__two">
        <Swiper
          className="swiper-container thm-swiper__slider"
          modules={[Navigation, Autoplay]}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: "#main-slider__swiper-button-next",
            prevEl: "#main-slider__swiper-button-prev",
          }}
          autoplay={{ delay: 5000 }}
        >
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL +
                      "/assets/images/main-slider/main-slider-1.jpg"
                    })`,
                  }}
                ></div>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8">
                      <p>Welcome to Tera Capital</p>
                      <h2>Building A Brighter Energy Future</h2>
                      <div className="button-wrap">
                        <Link to="/our-business" className=" thm-btn">
                          Learn More
                        </Link>
                        {/* <Link to="/contact" className=" thm-btn">
                          Contact Us
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL +
                      "/assets/images/main-slider/main-slider-2.jpg"
                    })`,
                  }}
                ></div>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8">
                      <p>At the Core of Our Culture</p>
                      <h2>Creating Lasting Partnerships</h2>
                      <div className="button-wrap">
                        <Link to="/partnerships" className=" thm-btn">
                          Learn More
                        </Link>
                        {/* <Link to="/contact" className=" thm-btn">
                          Contact Us
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL +
                      "/assets/images/main-slider/main-slider-3.jpg"
                    })`,
                  }}
                ></div>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8">
                      <p>A Data Driven Investment Company</p>
                      <h2>Leveraging Technology</h2>
                      <div className="button-wrap">
                        <Link to="/technologies" className=" thm-btn">
                          Learn More
                        </Link>
                        {/* <Link to="/contact" className=" thm-btn">
                          Contact Us
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </div>
          <div className="main-slider__nav">
            <div
              className="swiper-button-prev"
              id="main-slider__swiper-button-next"
            >
              <i className="tera-icon-left-arrow"></i>
            </div>
            <div
              className="swiper-button-next"
              id="main-slider__swiper-button-prev"
            >
              <i className="tera-icon-right-arrow"></i>
            </div>
          </div>
        </Swiper>
      </section>
    </>
  );
}

export default Slider;
