import React, { useState } from "react";

function WeServe() {
  const [activeFaq, setActiveFaq] = useState([false, false, false, false]);

  const itemList = [
    {
      title: "Market Intelligence",
      content1: "•	Power grid infrastructure analysis at scale",
      content2: "•	Global monitoring of power generation assets",
      content3: "•	Global monitoring of market participants",
      content4: "•	Development of tools and software for valuing projects ",
    },
    {
      title: "Project Development",
      content1: "• Land selection and feasibility studies",
      content2: "• Grid connection study and application",
      content3: "• Planning and permitting",
      content4: "• Project layout and financial modelling",
    },
    {
      title: "Construction",
      content1: "• Negotiations of EPC and O&M contracts",
      content2: "• Sourcing PPA and project finance",
      content3: "• Construction monitoring and management",
      content4: "• Relationship with local authorities",
    },
    {
      title: "Asset Management",
      content1: "• Asset monitoring and management",
      content2: "• Performance driven portfolio optimization",
      content3: "• Porfolio legal and financial restructuring",
      content4: "• Stakeholder relationship management",
    },
  ];

  const handleAccordionClick = (index) => {
    setActiveFaq(activeFaq.map((value, i) => (i === index ? !value : value)));
  };

  return (
    <>
      <section className="faq-one">
        <div
          className="faq-one__bg"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/images/backgrounds/trust-bg.png"
            })`,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="faq-one__content">
                <div className="block-title text-left">
                  <p>Our Expertise in the</p>
                  <h2>Investment Cycle</h2>
                </div>
                <p>
                  Tera Capital's team possesses a broad range of expertise in
                  the power generation asset investment cycle. Our specialists
                  may get involved at any stage of development of a power
                  generation asset.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <ul
                id="accordion"
                className="mb-0 wow fadeInUp list-unstyled"
                data-wow-duration="1500ms"
              >
                {itemList.map((faq, index) => (
                  <li key={index}>
                    <h2
                      className={`para-title ${activeFaq[index] && "active"}`}
                      onClick={() => handleAccordionClick(index)}
                    >
                      <span
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        data-target={`#collapse-${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${index}`}
                      >
                        {activeFaq[index] ? (
                          <i className="far fa-minus"></i>
                        ) : (
                          <i className="far fa-plus"></i>
                        )}
                        {faq.title}
                      </span>
                    </h2>
                    <div
                      id={`collapse-${index}`}
                      className={`collapse ${activeFaq[index] ? "show" : ""}`}
                      aria-labelledby={`collapse-${index}`}
                      data-parent="#accordion"
                    >
                      <p>
                        <li>{faq.content1}</li>
                        <li>{faq.content2}</li>
                        <li>{faq.content3}</li>
                        <li>{faq.content4}</li>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WeServe;
