import React from "react";
import { Link } from "react-router-dom";
import { Email } from "react-obfuscate-email";

function Leave() {
  return (
    <>
      <section className="contact-one">
        <div className="container">
          <div className="block-title text-center">
            <p>Contact Us</p>
            <h2>Let's Connect</h2>
          </div>
          {/* <div className="row"> */}
          {/* <div className="col-lg-5"> */}
          <div className="contact-one__content">
            <div className="contact-one__box">
              <i className="tera-icon-telephone"></i>
              <div className="contact-one__box-content">
                <h4>Call Us Now</h4>
                <a href="tel:+35319039642">+353 1 903 9642</a>
              </div>
            </div>
            <div className="contact-one__box">
              <i className="tera-icon-email1"></i>
              <div className="contact-one__box-content">
                <h4>Press Enquiries</h4>
                <Email email="press@teracapital.io">press@teracapital.io</Email>
              </div>
            </div>
            <div className="contact-one__box">
              <i className="tera-icon-email1"></i>
              <div className="contact-one__box-content">
                <h4>General Info</h4>
                <Email email="info@teracapital.io">info@teracapital.io</Email>
              </div>
            </div>
            <div className="contact-one__box">
              <i className="tera-icon-pin1"></i>
              <div className="contact-one__box-content">
                <h4>Visit Our Office</h4>
                <Link to="#">Suite 1, Charlotte Quay,</Link>
                <Link to="#">Grand Canal Dock, Dublin 4, Ireland</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Leave;
