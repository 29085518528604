import { Helmet } from "react-helmet";

const Seo = ({ pageTitle, id }) => {
  const sdata = {
    "@context": "https://schema.org/",
    type: "WebSite",
    "@id": "https://teracapital.io/",
    name: "Tera Capital",
    about: "Tera Capital invests in renewable energy power plants",
    keywords:
      "Power plant investments, investor in renewable energy, solar farm investors, wind farm investors, renewable energy investors, green energy funds",
    description:
      "Tera Capital provides financing to solar and wind project developers",
    logo: "https://teracapital.io/assets/images/logo-dark.svg",
    inLanguage: "en",
    publisher: "Tera Capital",
    url: "www.teracapital.io/",
    copyrightHolder: "Tera Power Ltd",
  };

  const seo = {
    home: {
      robots: "index",
      keywords:
        "Tera Capital home page, official Tera Capital website, Tera Capital renewable energy investor, fund manager, renewable energy investors",
      description:
        "Discover Tera Capital and the firm's strategies for investing in renewable energy power plants.",
      structuredData: sdata,
    },
    contact: {
      robots: "index",
      keywords:
        "Contact Tera Capital, Tera Capital email address, Tera Capital office address, Tera Capital contact details, connect with Tera Capital's team",
      description: "Tera Capital - contact a member of our team today.",
      structuredData: sdata,
    },
    business: {
      robots: "index",
      keywords:
        "Tera Capital business activities, Tera Capital line of business, Helping Businesses with their Clean Energy Transition Goals, Deploying Clean Energy Generators Faster, Tera Capital cares about Communities",
      description:
        "Tera Capital invests equity and provides debt to qualified renewable energy projects.",
      structuredData: sdata,
    },
    partner: {
      robots: "index",
      keywords:
        "Tera Capital partnerships, Doing Business With Tera Capital, procurement and diligence process, Diversity & Sustainability",
      description:
        "Doing Business With Tera Capital, building long term partnerships is part of our culture.",
      structuredData: sdata,
    },
    tech: {
      robots: "index",
      keywords:
        "Tera Capital technologies, Tera Data Engine, Tera Power intelligence platform, renewable energy market intelligence",
      description:
        "Learn about the technologies that make Tera Capital's business possible as a renewable energy investment company.",
      structuredData: sdata,
    },
    conduct: {
      robots: "index",
      keywords:
        "Tera Capital code of conduct, Tera Capital Code of Business Conduct",
      description: "Learn about Tera Capital's Code of Business Conduct",
      structuredData: sdata,
    },
    ethics: {
      robots: "index",
      keywords:
        "Tera Capital code of ethics, Tera Capital Code of Conduct and Ethics",
      description: "Learn about Tera Capital's Code of Ethics",
      structuredData: sdata,
    },
    privacy: {
      robots: "index",
      keywords: "Tera Capital privacy policies, Tera Capital privacy policy",
      description: "Tera Capital's Privacy Policy",
      structuredData: sdata,
    },
    terms: {
      robots: "index",
      keywords: "Tera Capital terms and conditions, Tera Capital terms of use",
      description: "Tera Capital's Terms and Conditions",
      structuredData: sdata,
    },
    team: {
      robots: "noindex",
      keywords:
        "Tera Capital's leadership team, Tera's team, Tera Capital board members",
      description:
        "Learn more about Tera Capital's leadership team and board members.",
      structuredData: sdata,
    },
    faqs: {
      robots: "index",
      keywords: "Tera Capital frequently asked questions, Tera Capital FAQs",
      description: "FAQs - Frequently Asked Questions.",
      structuredData: sdata,
    },
  };

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="robots" content="index" />
        <title>{pageTitle && `${pageTitle} - Tera Capital`}</title>

        <meta name="keywords" content={id && seo[id].keywords} />
        <meta name="description" content={id && seo[id].description} />
        <script type="application/ld+json">
          {JSON.stringify(id && seo[id].structuredData)}
        </script>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://teracapital.io/" />
        <meta
          property="og:title"
          content={pageTitle && `${pageTitle} - Tera Capital`}
        />
        <meta property="og:description" content={id && seo[id].description} />
        <meta
          property="og:image"
          content="https://teracapital.io/assets/images/favicons/ss1.png"
        />
        <meta property="twitter:card" content="website" />
        <meta property="twitter:url" content="https://teracapital.io/" />
        <meta
          property="twitter:title"
          content={pageTitle && `${pageTitle} - Tera Capital`}
        />
        <meta
          property="twitter:description"
          content={id && seo[id].description}
        />
        <meta
          property="twitter:image"
          content="https://teracapital.io/assets/images/favicons/ss1.png"
        />
        <meta name="copyright" content="Tera Capital" />
        <meta name="application-name" content="Tera Capital" />
        <link rel="icon" href="https://teracapital.io/favicon.ico" />
        <link
          rel="apple-touch-icon"
          href="https://teracapital.io/assets/images/favicons/logo192.png"
        />
        <link rel="sitemap" href="https://teracapital.io/sitemap.xml" />
      </Helmet>
    </>
  );
};

export default Seo;
