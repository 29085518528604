import React from "react";
import { Link } from "react-router-dom";

function DataScience() {
  return (
    <>
      <section className="about-two">
        {/* <img
          src="assets/images/shapes/about-shape-1-1.png"
          className="about-two__shape-1"
          alt=""
        />
        <img
          src="assets/images/shapes/about-shape-1-2.png"
          className="about-two__shape-2"
          alt=""
        />
        <img
          src="assets/images/shapes/about-shape-1-3.png"
          className="about-two__shape-3"
          alt=""
        /> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
              <div className="about-two__image">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/resources/light-bulb.jpg"
                  }
                  alt=""
                />
                {/* <div className="about-two__box">
                  <i className="tera-icon-consumer-behavior"></i>
                  <h3>17 Years of Expericence</h3>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-two__content">
                <div className="block-title text-left">
                  <p>A Unique Approach</p>
                  <h2>Data Science is at the Core of Our Investment Thesis</h2>
                </div>
                <p>
                  The Tera Data Engine is a data aggregator providing granular
                  information on public and private grid infrastructure assets
                  in 130 countries. We are able to derive unique insights on
                  power generation assets from our exclusive datasets on the
                  power grid.
                </p>
                <ul className="list-unstyled about-two__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Asset Analysis at Scale
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Global Power Grid Monitoring
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Lower Due Diligence Costs
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Lower Investment Risks
                  </li>
                </ul>
                <div className="button-wrap">
                  <Link to="technologies" className="thm-btn">
                    Discover More
                  </Link>
                  <div className="main-header__info-phone">
                    <i className="tera-icon-tech-support"></i>
                    <div className="main-header__info-phone-content">
                      <span>Call Us to Learn More</span>
                      <h3>
                        <Link to="tel:92-666-888-0000">+353 1 903 9642</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DataScience;
