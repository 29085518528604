import React from "react";
import Breadcrumb from "../../Breadcrumb/Main";
import First from "./Content";
import Seo from "../../../utils/seo";

const ServiceDetails = () => {
  return (
    <>
      <Seo pageTitle="Terms & Conditions" id="terms" />
      <Breadcrumb
        title={"Home"}
        subtitle={"Terms & Conditions"}
        common={"Terms & Conditions"}
      />
      <First />
    </>
  );
};

export default ServiceDetails;
