import { Link } from "react-router-dom";
import React from "react";

function First() {
  function handleScrollTo(item) {
    const element = document.getElementById(item);
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <section className="service-details faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__category">
                  <h3>Quick Menu</h3>
                  <ul className="list-unstyled service-sidebar__category-list">
                    <li>
                      <Link onClick={() => handleScrollTo("1")} to="#">
                        Disclaimer
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("2")} to="#">
                        Fund and Advisor Disclosures
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("3")} to="#">
                        Intellectual Property
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("4")} to="#">
                        Links to Other Websites
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("5")} to="#">
                        Links to Tera Capital
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("6")} to="#">
                        Transmissions to and From this Website
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__content">
                <h3 className="mt-20">Terms & Conditions</h3>
                <p>
                  READ THESE TERMS AND CONDITIONS ("TERMS") CAREFULLY BEFORE
                  USING THE SERVICES DESCRIBED HEREIN. BY UTILIZING THE WEBSITE
                  LOCATED AT www.teracapital.io ("WEBSITE"), YOU ACKNOWLEDGE
                  THAT YOU HAVE READ THESE TERMS AND CONDITIONS AND THAT YOU
                  AGREE TO BE BOUND BY THEM. IF YOU DO NOT AGREE TO ALL OF THE
                  TERMS AND CONDITIONS OF THIS AGREEMENT, YOU ARE NOT AN
                  AUTHORIZED USER OF THESE SERVICES AND YOU SHOULD NOT USE THIS
                  WEBSITE.
                </p>
                <p id="1">
                  TERA CAPITAL RESERVES THE RIGHT TO CHANGE, MODIFY, ADD OR
                  REMOVE PORTIONS OF THESE TERMS AT ANY TIME FOR ANY REASON. WE
                  SUGGEST THAT YOU REVIEW THESE TERMS PERIODICALLY FOR CHANGES.
                  SUCH CHANGES SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING. YOU
                  ACKNOWLEDGE THAT BY ACCESSING OUR WEBSITE AFTER WE HAVE POSTED
                  CHANGES TO THESE TERMS, YOU ARE AGREEING TO THESE TERMS AS
                  MODIFIED.
                </p>
                <h4 className="mt-30">Disclaimer</h4>
                <p>
                  Nothing contained on this Website constitutes tax, accounting,
                  regulatory, legal, insurance or investment advice. Neither the
                  information, nor any opinion, contained on this Website
                  constitutes a solicitation or offer by Tera Capital or its
                  affiliates to buy or sell any securities, futures, options or
                  other financial instruments, nor shall any such security be
                  offered or sold to any person in any jurisdiction in which
                  such offer, solicitation, purchase, or sale would be unlawful
                  under the securities laws of such jurisdiction. Decisions
                  based on information contained on this Website are the sole
                  responsibility of the visitor. In exchange for using this
                  Website, the visitor agrees to indemnify and hold Tera
                  Capital, its officers, directors, employees, affiliates,
                  agents, licensors and suppliers harmless against any and all
                  claims, losses, liability, costs and expenses (including but
                  not limited to attorneys' fees) arising from your use of this
                  Website, from your violation of these Terms or from any
                  decisions that the visitor makes based on such information.
                </p>
                <p>
                  <b>
                    The investments and strategies discussed in the Website may
                    not be suitable for all investors and are not obligations of
                    Tera Capital or its affiliates or guaranteed by Tera Capital
                    or its affiliates. Tera Capital makes no representations
                    that the contents are appropriate for use in all locations,
                    or that the transactions, securities, products, instruments,
                    or services discussed on this site are available or
                    appropriate for sale or use in all jurisdictions or
                    countries, or by all investors or counterparties. By making
                    available information on the Website, Tera Capital does not
                    represent that any investment vehicle is available or
                    suitable for any particular user. All persons and entities
                    accessing the Web Site do so on their own initiative and are
                    responsible for compliance with applicable local laws and
                    regulations.{" "}
                  </b>
                </p>
                <p>
                  All investments involve risk and may lose value. The value of
                  your investment can go down depending upon market conditions.
                  Currency exchange rates may cause the value of an investment
                  to go up or down. Alternative strategies involve higher risks
                  than traditional investments, may not be tax efficient, and
                  have higher fees than traditional investments; they may also
                  be highly leveraged and engage in speculative investment
                  techniques, which can magnify the potential for investment
                  loss or gain.{" "}
                </p>
                <p>
                  This Website is for information purposes only and is not
                  intended to be relied upon as a forecast, research or
                  investment advice. The information on this Website does not
                  constitute a recommendation, offer or solicitation to buy or
                  sell any securities or to adopt any investment strategy.
                  Although this material is based upon information that Tera
                  Capital considers reliable and endeavours to keep current,
                  Tera Capital does not assure that this material is accurate,
                  current or complete, and it should not be relied upon as such.
                  Any opinions expressed on this Website may change as
                  subsequent conditions vary. Past performance is no guarantee
                  of future results.
                </p>
                <p id="2">
                  The information and services provided on this Website are
                  provided "AS IS" and without warranties of any kind, either
                  expressed or implied. To the fullest extent permissible
                  pursuant to applicable law, Tera Capital disclaims all
                  warranties, including, but not limited to, any warranty of
                  non-infringement of third-party rights and any implied
                  warranties of merchantability and fitness for a particular
                  purpose. Tera Capital does not warrant, either expressly or
                  impliedly, the accuracy or completeness of the information,
                  text, graphics, links or other items contained on this Website
                  and does not warrant that the functions contained in this
                  Website will be uninterrupted or error-free, that defects will
                  be corrected, or that the Website will be free of viruses or
                  other harmful components. Tera Capital expressly disclaims all
                  liability for errors and omissions in the materials on this
                  Website and for the use or interpretation by others of
                  information contained on the Website.
                </p>
                <h4 className="mt-30">Fund and Advisor Disclosures</h4>
                <p id="3">
                  Investments in a Tera Capital product or client account are
                  not bank deposits and are not insured or guaranteed by Tera
                  Capital or the Federal Deposit Insurance Corporation, any
                  other government agency or any bank. It is possible to lose
                  money by investing in the fund.{" "}
                  <i>
                    Please carefully consider a fund's investment objective,
                    risks, charges and expenses before investing. For this and
                    other information, call or write to Tera Capital for a free
                    prospectus. Read it carefully before you invest or send
                    money.
                  </i>
                </p>
                <h4 className="mt-30">
                  Trademarks, Copyrights and other Intellectual Property
                </h4>
                <p>
                  The content contained on this Website is owned or licensed by
                  Tera Capital and its third-party information providers and is
                  protected by applicable copyrights, trademarks, service marks,
                  and/or other intellectual property rights. Such content is
                  solely for your personal, non-commercial use. Accordingly, you
                  may not copy, distribute, modify, post, frame or deep link
                  this Website, including any text, graphics, video, audio,
                  software code, user interface design or logos. You may
                  download material displayed on this Website for your personal
                  use provided you also retain all copyright and other
                  proprietary notices contained on the materials. You may not
                  distribute, modify, transmit, reuse, repost, or use the
                  content of this Website for public or commercial purposes,
                  including all text, images, audio, and video, without Tera
                  Capital's written permission. Modification or use of the
                  materials for any other purpose violates Tera Capital's
                  intellectual property rights.
                </p>
                <p>
                  All trademarks, service marks, trade names, and logos
                  displayed on this Website are proprietary to Tera Capital
                  and/or their respective owners. Nothing contained on this
                  Website should be construed as granting, by implication,
                  estoppel, or otherwise, any license or right to use any
                  trademark displayed on this Website without the written
                  permission of Tera Capital or such other third party that may
                  own the trademark displayed on this Website. Your use of the
                  trademarks displayed on this Website, except as provided
                  herein, is strictly prohibited.
                </p>
                <p id="4">
                  The use of the images displayed on this Website by you, or
                  anyone else authorized by you, is prohibited. Any unauthorized
                  use of the images may violate copyright laws, trademark laws,
                  and the laws of privacy and publicity, and communications, as
                  well as other regulations and statutes. If you download any
                  information from this Website, you agree that you will not
                  copy it or remove or obscure any copyright or other notices or
                  legends contained in any such information.{" "}
                </p>
                <h4 className="mt-30">Links to Other Websites</h4>
                <p id="5">
                  Tera Capital may establish links between this Website and one
                  or more websites operated by third parties. Tera Capital has
                  no control over any such other websites, the contents therein
                  or the products/services offered. The existence of any such
                  links shall not constitute an endorsement of, or
                  representation or warranty by Tera Capital regarding such
                  websites, the contents of the websites, the products or
                  services of the websites or the operators of the websites.
                  Your access to and use of such linked websites is governed by
                  the terms of use and privacy policies of those sites, and
                  shall be at your own risk. Tera Capital disclaims
                  responsibility for the privacy policies and customer
                  information practices of third-party internet websites
                  hyperlinked from our Website.
                </p>
                <h4 className="mt-30">
                  Links to Tera Capital from Other Websites
                </h4>
                <p id="6">
                  You shall not display hyperlinks on your websites to any
                  website owned or operated by Tera Capital. If you desire to
                  display on your website a hyperlink to a Tera Capital website,
                  you must enter into a written agreement with Tera Capital
                  governing such display. Access to any Tera Capital website
                  does not authorize you to use any of Tera Capital's names,
                  logos, trademarks or copyrighted material, and you agree not
                  to do so without Tera Capital's express written consent.
                  Requests to display hyperlinks on your websites to Tera
                  Capital websites should be e-mailed to
                  webmaster@teracapital.io.
                </p>
                <h4 className="mt-30">
                  Transmissions to and From this Website
                </h4>
                <p>
                  Electronic communications can be intercepted by third parties
                  and, accordingly, transmissions to and from this Website may
                  not be secure. Communications to Tera Capital, particularly
                  those containing confidential information, may be sent by mail
                  to: Tera Capital, Suite 1, The Anchorage, Charlotte Quay,
                  Grand Canal Dock, Dublin 4, Ireland, Attn: Webmaster. Tera
                  Capital shall be free to use, for any purpose, any ideas,
                  concepts, know-how, or techniques provided by a website user
                  to Tera Capital through this Website. You acknowledge and
                  agree that Tera Capital has granted you limited access to the
                  specific files pertaining to the Tera Capital Funds ("Approved
                  Files"), and you agree not to attempt to access computer files
                  other than the Approved Files. Tera Capital makes no warranty
                  to you regarding the security of the Website, including with
                  regard to the ability of any unauthorized persons to access
                  information received or transmitted by you through or from the
                  Website. Tera Capital reserves the right to cease providing,
                  or to change, this Website and content of such information (or
                  any portion or feature thereof) at any time or frequency and
                  without notice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
