import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import { Email } from "react-obfuscate-email";

function Headertwo() {
  const [mobile, setmobile] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [commitmentDrop, setcommitmentDrop] = useState(false);
  const [search, setsearch] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const [menu, setmenu] = useState({});

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    const activeMenu = () => {
      if (path === "/") {
        setmenu({ home: true });
      } else if (path === "/our-business") {
        setmenu({ business: true });
      } else if (path === "/technologies") {
        setmenu({ tech: true });
      } else if (path === "/partnerships") {
        setmenu({ partnerships: true });
      } else if (path === "/team" || path.includes("/team-member")) {
        setmenu({ team: true });
      } else if (
        path === "/code-of-conduct" ||
        path === "/code-of-ethics" ||
        path === "/privacy" ||
        path === "/terms"
      ) {
        setmenu({ commitment: true });
      } else if (path === "/contact") {
        setmenu({ contact: true });
      } else {
        setmenu({ home: true });
      }
    };

    window.scroll(0, 0);
    new WOW.WOW({
      live: false,
    }).init();
    activeMenu();
  }, [path]);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  };

  return (
    <>
      <header className="main-header main-header__two">
        <nav
          className={`main-menu main-menu__two ${
            sticky &&
            "stricky-header stricked-menu main-menu main-menu__two stricky-fixed"
          }`}
        >
          <div className="container">
            <div className="logo-box">
              <Link to="/" aria-label="logo image" label="home">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/logo-light.svg"}
                  width="120"
                  alt="logo"
                />
              </Link>
              <span
                onClick={() => setmobile(true)}
                className="fa fa-bars mobile-nav__toggler"
              ></span>
            </div>
            <ul className="main-menu__list">
              <li className={`dropdown ${menu.business && "current"}`}>
                <Link to="/our-business">Our Business</Link>
              </li>
              <li className={`dropdown ${menu.partnerships && "current"}`}>
                <Link to="/partnerships">Partnerships</Link>
              </li>
              <li className={`dropdown ${menu.tech && "current"}`}>
                <Link to="/technologies">Technologies</Link>
              </li>
              {/* <li className={`dropdown ${menu.team && "current"}`}>
                <Link to="/team">Leadership</Link>
              </li> */}
              <li className={`dropdown ${menu.contact && "current"}`}>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setmobile(false)}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={() => setmobile(false)}
          >
            <i className="far fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to="/" aria-label="logo image">
              <img
                src={process.env.PUBLIC_URL + "assets/images/logo-light.svg"}
                width="120"
                alt="logo"
              />
            </Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current">
                <Link onClick={() => setmobile(false)} to="/">
                  Home
                </Link>
              </li>
              <li className="dropdown current">
                <Link onClick={() => setmobile(false)} to="/our-business">
                  Our Business
                </Link>
              </li>
              <li className="dropdown current">
                <Link onClick={() => setmobile(false)} to="/partnerships">
                  Partnerships
                </Link>
              </li>
              <li className="dropdown current">
                <Link onClick={() => setmobile(false)} to="/technologies">
                  Technologies
                </Link>
              </li>
              <li className="dropdown current">
                <Link onClick={() => setmobile(false)} to="/team">
                  Leadership
                </Link>
              </li>

              <li className="dropdown">
                <Link to="#" className={commitmentDrop ? "expanded" : ""}>
                  Our Commitment
                  <button
                    aria-label="dropdown toggler"
                    className={commitmentDrop ? "expanded" : ""}
                    onClick={() =>
                      setcommitmentDrop(commitmentDrop ? false : true)
                    }
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {commitmentDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link
                        to="/code-of-ethics"
                        onClick={() => setmobile(false)}
                      >
                        Code of Ethics
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/code-of-conduct"
                        onClick={() => setmobile(false)}
                      >
                        Code of Conduct
                      </Link>
                    </li>
                  </ul>
                )}
              </li>

              <li>
                <Link to="/contact" onClick={() => setmobile(false)}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="tera-icon-email1"></i>

              <Email email="press@teracapital.io" className="text-left">
                press@teracapital.io
              </Email>
            </li>
            <li>
              <i className="tera-icon-telephone"></i>
              <a href="tel:+35319039642">+353 1 903 9642</a>
            </li>
          </ul>
        </div>
      </div>

      <div className={`search-popup ${search && "active"}`}>
        <div
          className="search-popup__overlay search-toggler"
          onClick={() => setsearch(false)}
        ></div>
        <div className="search-popup__content">
          <form action="#">
            <label htmlFor="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Headertwo;
