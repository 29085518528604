// import ActionBG1 from "../../assets/images/backgrounds/call-to-action-bg-1-1.jpg";
import { Link } from "react-router-dom";
import React from "react";

function Ethics() {
  return (
    <>
      <section
        className="call-to-action"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/assets/images/backgrounds/signing.jpg"
          })`,
        }}
      >
        <div className="container">
          <div className="left-content">
            <p>
              <span>Sustainable</span>
              <span>Data Driven</span>
              <span>Low Risk Profile</span>
            </p>
            <h3>Deploying Capital Ethically</h3>
          </div>
          <div className="right-content">
            <Link to="partnerships" className="thm-btn">
              Learn More
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ethics;
