import { Link } from "react-router-dom";
import React from "react";

function Services() {
  return (
    <>
      <section className="service-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
              <div className="service-two__card">
                <i className="tera-icon-strategy"></i>
                <h3>
                  <Link to="/our-business">Equity Financing</Link>
                </h3>

                <p>
                  We invest equity in renewable energy projects and operating
                  power assets.
                </p>
                <Link to="/our-business" className="service-two__card-link">
                  <i className="tera-icon-right-arrow"></i>Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
              <div className="service-two__card">
                <i className="tera-icon-assets"></i>
                <h3>
                  <Link to="/our-business">Structured Finance</Link>
                </h3>
                <p>
                  We develop structured finance strategies for large or complex
                  projects.
                </p>
                <Link to="/our-business" className="service-two__card-link">
                  <i className="tera-icon-right-arrow"></i>Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms">
              <div className="service-two__card">
                <i className="tera-icon-point-of-sale"></i>
                <h3>
                  <Link to="/our-business">Financial Services</Link>
                </h3>
                <p>
                  We provide a range of services to renewable energy market
                  participants.
                </p>
                <Link to="/our-business" className="service-two__card-link">
                  <i className="tera-icon-right-arrow"></i>Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
