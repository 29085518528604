import { Link } from "react-router-dom";
import React from "react";
import Breadcrumb from "../../Breadcrumb/Main";
import Seo from "../../../utils/seo";
import { Email } from "react-obfuscate-email";

const Service = () => {
  function handleScrollToBusiness() {
    const element = document.getElementById("dobusiness");
    element.scrollIntoView({ behavior: "smooth" });
  }

  function handleScrollToProcurement() {
    const element = document.getElementById("procurement");
    element.scrollIntoView({ behavior: "smooth" });
  }
  function handleScrollToDiversity() {
    const element = document.getElementById("diversity");
    element.scrollIntoView({ behavior: "smooth" });
  }
  function handleScrollToEthics() {
    const element = document.getElementById("ethics");
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <Seo pageTitle="Partnerships" id="partner" />
      <Breadcrumb
        title={"Home"}
        subtitle={"Partnerships"}
        common={"Partnerships"}
      />

      <section className="service-details faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__category">
                  <h3>Quick Menu</h3>
                  <ul className="list-unstyled service-sidebar__category-list">
                    <li>
                      <Link onClick={handleScrollToBusiness} to="#">
                        Doing Business With Tera Capital
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleScrollToProcurement} to="#">
                        Our procurement and diligence process
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleScrollToDiversity} to="#">
                        Diversity & Sustainability
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleScrollToEthics} to="#">
                        Standards of supplier ethics & conduct
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="service-sidebar__call">
                  <div
                    className="service-sidebar__call-bg"
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL +
                        "/assets/images/partnerships/call-us-today.jpg"
                      })`,
                    }}
                    // style={{ backgroundImage: `url(${SSBAR1})` }}
                  ></div>
                  <i className="tera-icon-tech-support"></i>
                  <h3>
                    <a href="tel:+35319039642">+353 1 903 9642</a>
                  </h3>
                  <Email email="info@teracapital.io">info@teracapital.io</Email>

                  <p>
                    Give us a call to get more information on our philosophies
                    and values regarding partnerships.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__content">
                <div id="dobusiness" className="service-details__image">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partnerships/partnerships-1.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="mt-30">Doing Business With Tera Capital</h3>
                <p>
                  Tera Capital's purpose is to help more and more people get
                  access to reliable, cheap, and clean energy. Consistent with
                  Tera Capital’s values, we are dedicated to establishing
                  mutually beneficial third-party partnerships.
                </p>
                <p>
                  Tera Capital is committed to seeking out third-party
                  partnerships who can provide high quality, innovative and
                  competitively priced energy services to meet our business
                  requirements. Tera Capital believes leading institutions
                  achieve optimal performance by incorporating diversity and
                  sustainability within its business practices – including
                  developing relationships with diverse-owned businesses within
                  its supply base. As such, Tera Capital is committed to
                  fostering the growth and development of veteran, minority and
                  women-owned businesses in the communities in which it
                  operates. Explore these resources designed to help you do
                  business with Tera Capital.
                </p>
                <div id="procurement" className="service-details__image mt-40">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partnerships/partnerships-4.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="mt-30">Our procurement and diligence process</h3>
                <p>
                  Tera Capital values its business partners and views its
                  suppliers as an extension of ourselves. As a result, Tera
                  Capital requires its suppliers to undergo thorough financial,
                  operational, risk and contract diligence process on a
                  consistent basis.
                </p>
                <p>
                  Tera Capital is focused on growing long-term relationships
                  that are sustainable and create joint value.
                </p>
                <div id="diversity" className="service-details__image mt-40">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partnerships/partnerships-5.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="mt-30">Diversity & Sustainability</h3>
                <p>
                  Tera Capital places a high priority in fostering relationships
                  with Diverse and Sustainable third-parties as well as those
                  with a similar viewpoint. When evaluating such companies,
                  these factors will be considered along with quality, service,
                  and pricing competitiveness.
                </p>
                <div id="ethics" className="service-details__image mt-40">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partnerships/partnerships-2.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="mt-30">
                  Standards of supplier ethics & conduct
                </h3>
                <p>
                  Tera Capital’s Supplier Code of Conduct & Ethics outlines the
                  minimum expectations and standards for all Tera Capital
                  Suppliers in relation to human rights, inclusion and
                  diversity, environmental sustainability, integrity and ethics
                  in management practices.
                </p>
                <p>
                  Read more about the{" "}
                  <Link to="/code-of-ethics">
                    Tera Code of Conduct & Ethics
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
