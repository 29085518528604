import React from "react";
import Breadcrumb from "../Breadcrumb/Main";
import Leave from "./Leave";
import Seo from "../../utils/seo";

const Contact = () => {
  return (
    <>
      <Seo pageTitle="Contact" id="contact" />
      <Breadcrumb
        title={"Home"}
        subtitle={"Contact Us"}
        common={"Contact Us"}
      />
      <Leave />
    </>
  );
};

export default Contact;
