import { Link } from "react-router-dom";
import React from "react";

function Content() {
  function handleScrollTo(item) {
    const element = document.getElementById(item);
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <section className="service-details faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__category">
                  <h3>Quick Menu</h3>
                  <ul className="list-unstyled service-sidebar__category-list">
                    <li>
                      <Link onClick={() => handleScrollTo("1")} to="#">
                        Human Rights and Labor Practices
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("2")} to="#">
                        Diversity, Equity & Inclusion
                      </Link>
                    </li>

                    <li>
                      <Link onClick={() => handleScrollTo("3")} to="#">
                        Environmental Sustainability
                      </Link>
                    </li>

                    <li>
                      <Link onClick={() => handleScrollTo("4")} to="#">
                        Integrity, Ethics & Anti-Corruption
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("5")} to="#">
                        Applicable Laws & Regulations
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__content">
                <h3 className="mt-20">Code of Conduct & Ethics</h3>
                <p id="1">
                  Tera Capital prides itself on its reputation for conducting
                  business activities in the highest ethical and professional
                  manner. Tera Capital considers methods to implement practices
                  that align its corporate operations with the universal
                  principles on human rights, labor, the environment and
                  anti-corruption. As such, Tera Capital expects all companies
                  and individuals which supply goods, materials or services to
                  Tera Capital (“Suppliers”) to seek to adhere to these same
                  standards and principles or their equivalent. This Tera
                  Capital Supplier Code of Conduct & Ethics (“Code”) outlines
                  the minimum expectations and standards of all Tera Capital
                  Suppliers in relation to human rights, diversity, equity and
                  inclusion, environmental sustainability, and integrity,
                  ethics, and anti-corruption in their management practices.
                </p>

                <h4 className="mt-30">Human Rights and Labor Practices</h4>
                <p>
                  As defined by{" "}
                  <Link to="https://www.ohchr.org/sites/default/files/UDHR/Documents/UDHR_Translations/eng.pdf">
                    the United Nations
                  </Link>
                  , human rights are inherent to all human beings and include
                  the right to life, health and well-being, privacy, fair wages
                  and decent working conditions; freedom from discrimination,
                  slavery and torture; and freedom of association. Tera Capital
                  expects its Suppliers to maintain processes to identify,
                  manage, and prevent adverse human rights impacts that could
                  arise from their operations and to demonstrate respect for
                  human rights in line with the expectations outlined in the UN
                  Global Compact Ten Principles. More specifically, Tera Capital
                  expects its Suppliers to:
                </p>
                <li className="mt-20">
                  <b>Provide a safe and secure workplace for their employees</b>
                  that fully complies with all health and safety laws,
                  regulations and practices. In addition, Suppliers shall ensure
                  that all employees are provided with appropriate health and
                  safety training, such as safe work practices and emergency
                  preparedness.
                </li>
                <li className="mt-20">
                  <b>Fully comply with all applicable laws and regulations</b>{" "}
                  when setting employee conditions on working hours, benefits
                  and wages (such as minimum legal wages).
                </li>
                <li className="mt-20">
                  <b>Fully comply with all applicable child labor laws</b> and
                  only employ workers who meet the minimum legal age for that
                  jurisdiction. Where local laws are less stringent than the
                  International Labor Organization (“ILO”) minimum age
                  convention, Tera Capital expects Suppliers to comply with ILO
                  standards.
                </li>

                <li className="mt-20">
                  <b>Not to use any involuntary labor</b>, such as slave,
                  forced, bonded, indentured or prison labor and not be involved
                  in any human trafficking or exploitation.
                </li>
                <li className="mt-20" id="2">
                  <b>Manage their employees in a fair and ethical manner</b> and
                  assure that all employees are treated with dignity and
                  respect. Tera Capital expects our Suppliers to maintain a
                  workplace that is free from unlawful discrimination and
                  harassment in any form. In addition, Suppliers should uphold
                  freedom of association, for openly communicating and, and
                  sharing ideas, concerns, or grievances with management
                  regarding working conditions, wages or benefits without fear
                  of discrimination, reprisal, intimidation or harassment; and
                  employees should have the right to collective bargaining and
                  be free to join a labor union, unless restricted under local
                  law.
                </li>
                <h4 className="mt-30">Diversity, Equity & Inclusion</h4>
                <p>
                  Tera Capital’s Diversity, Equity and Inclusion (DEI) practices
                  are grounded in its business needs as a global firm. One of
                  the firm’s core beliefs is that a diverse workforce with an
                  inclusive and connected culture is a commercial imperative and
                  indispensable to its success. Tera Capital prioritizes
                  creating a work environment where all employees can thrive.
                  Ultimately, a dynamic, inclusive organization allows the firm
                  to attract the best people around the world and to stay ahead
                  of where its clients are going. Tera Capital believes in
                  providing equal opportunity in all aspects of employment and
                  we expect the same from our Suppliers. Tera Capital expects
                  our Suppliers, in accordance with all applicable local
                  ordinances and laws, to afford equal opportunity to all
                  qualified applicants and existing employees without regard to
                  race, religion, color, national origin, sex (including
                  pregnancy and gender identity), sexual orientation, age,
                  ancestry, physical or mental disability, marital status,
                  political affiliation, citizenship status, genetic
                  information, or protected veteran status or any other basis
                  that would be in violation of any such ordinance or law.
                </p>
                <p id="3">
                  Tera Capital encourages suppliers to have in place programs
                  and initiatives to advance DEI within their business, in
                  compliance with applicable law, and to provide transparency
                  regarding representation as appropriate to their business and
                  the countries in which they operate. Programs and initiatives
                  to advance DEI should ideally cover recruitment, development,
                  retention and accountability. In addition, regular DEI
                  training for staff, managers, and senior leaders is
                  encouraged. Tera Capital may engage with Suppliers to track
                  progress, share best practices and advance DEI within their
                  businesses. Further, Tera Capital is committed to widening its
                  pool of suppliers by focusing on the inclusion of businesses
                  that are owned by minorities, women, military veterans,
                  disabled veterans, people with disabilities and members of the
                  LGBTQ+ community.
                </p>
                <h4 className="mt-30">Environmental Sustainability</h4>
                <p id="4">
                  Tera Capital expects all Suppliers to endeavor to minimize the
                  adverse effects of their own operations and suppliers on the
                  environment and natural resources. This includes, but is not
                  limited to, compliance with all applicable laws and
                  regulations in relation to the protection of the environment.
                  As a corporate entity, Tera Capital pursues an environmental
                  sustainability strategy that is focused on reducing greenhouse
                  gas (“GHG”) emissions and increasing the efficiency of the
                  firm’s operations, where possible. As such, Tera Capital
                  encourages its Suppliers to have strategies in place to reduce
                  their environmental impact, including measuring, reducing and
                  publicly reporting GHG emissions. In addition to tracking
                  progress on environmental commitments by Suppliers, Tera
                  Capital may actively engage with individual Suppliers to
                  encourage these practices as well as to set emissions
                  reduction goals.
                </p>

                <h4 className="mt-30">Integrity, Ethics & Anti-Corruption</h4>
                <p>
                  Tera Capital expects its Suppliers to act and conduct
                  themselves in the highest ethical manner in all business
                  dealings and interactions. Tera Capital expects all Suppliers
                  to:
                </p>
                <li className="mt-20">
                  Comply with applicable laws, rules, and regulations,
                  including, but not limited to, those relating to
                  Whistleblowing, insider trading, and/or labor or employment
                  regulations;
                </li>
                <li className="mt-20">
                  Report to Tera Capital any illegal or unethical conduct about
                  which Suppliers may become aware.
                </li>
                <li className="mt-20">
                  Disclose to Tera Capital any potential or perceived conflicts
                  of interest in relation to any bid, proposal, ongoing service
                  or account management;
                </li>
                <li className="mt-20">
                  Refrain from offering or making any payments of money or
                  anything of value (including kickbacks, favors, gifts,
                  gratuities, entertainment, travel, political contributions,
                  charitable donations or other business courtesies) to
                  customers, government officials, political parties, candidates
                  for public office, charities, or other business-related
                  parties that could be considered to improperly influence
                  business decisions. This includes a prohibition on
                  facilitating payments intended to expedite or secure
                  performance of a routine governmental action like obtaining a
                  visa or customs clearance, except in situations where there is
                  an imminent threat to personal health or safety;
                </li>
                <li className="mt-20">
                  Maintain accurate books and records in accordance with
                  applicable laws, regulations, and accepted accounting
                  practices;
                </li>
                <li className="mt-20" id="5">
                  Comply with anti-competition and antitrust laws and are
                  prohibited from fixing prices, colluding or rigging bids with
                  competitors, allocating customers or markets with competitors,
                  or exchanging any pricing information with ourcompetitors.
                </li>
                <p className="mt-20">
                  Exercise risk-based due diligence and monitoring to prevent
                  and detect corruption in all business arrangements, including
                  partnerships, joint ventures, the engagement of third parties.
                </p>
                <h4 className="mt-30">
                  Compliance with this Code and Applicable Laws & Regulations
                </h4>
                <p>
                  Tera Capital requires all its Suppliers (and their
                  subcontractors) to fully comply with all laws and regulatory
                  requirements applicable to such Supplier’s business
                  activities. Understanding that the standards set forth in this
                  Code may differ from local laws, regulations and customs, this
                  Code should still be read as applying globally. If the Code
                  conflicts with local laws or ordinances, Tera Capital expects
                  our Suppliers to notify Tera Capital of any such conflict in a
                  timely manner and communicate how the Supplier is planning to
                  adhere to the general principles articulated in the code and
                  operate in an ethical and responsible manner. Suppliers are
                  responsible for adhering to the Code. Suppliers are expected
                  to notify Tera Capital of any material deviation from the
                  expectations described in this Code and the corrective actions
                  that are being taken to address such deviations. We define a
                  material deviation as conduct that deviates from the Code,
                  which could be reasonably expected to affect the ability of
                  the Supplier to provide the agreed products or services to
                  Tera Capital, lead to regulatory fines, penalties or sanctions
                  for the Supplier or Tera Capital, or negatively affect the
                  Supplier’s or Tera Capital’s reputation. If Tera Capital is
                  not notified or if the corrective action plan is deemed
                  insufficient, Tera Capital reserves the right to take action
                  up to, but not limited to, terminating its relationship with
                  any Supplier who fails to adhere to this Code.
                </p>

                <p>
                  Relevant notifications should be immediately provided to the
                  primary Tera Capital contact or relationship manager and to
                  compliance@teracapital.io.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;
