import React from "react";

function WeServe() {
  return (
    <>
      <section className="faq-one">
        <div
          className="faq-one__bg"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/images/backgrounds/trust-bg.png"
            })`,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="faq-one__content">
                <div className="block-title text-left increased_width">
                  <p>Who We Are</p>
                  <h2>A Renewable Energy Company</h2>
                </div>
                <p>
                  Tera Capital is a tech-centric renewable energy company
                  headquartered in Ireland. The firm invests in renewable energy
                  power generation assets globally. Tera Capital uses a unique
                  approach based on data science for deal sourcing and
                  evaluation.
                </p>
                <p>
                  Tera Capital is engaged in developing software solutions for
                  better analysing assets, improving due-diligence processes,
                  and more broadly accelerating deal flows while maintaining a
                  rigorous approach to risk management.
                </p>
                <p>
                  Tera Capital's expertise covers the whole investment cycle,
                  from the identification and acquisition of a single project to
                  the management and optimization of energy generation asset
                  portfolios.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WeServe;
