import { Link } from "react-router-dom";
import React from "react";

function Content() {
  function handleScrollTo(item) {
    const element = document.getElementById(item);
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <section className="service-details faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__category">
                  <h3>Quick Menu</h3>
                  <ul className="list-unstyled service-sidebar__category-list">
                    <li>
                      <Link onClick={() => handleScrollTo("1")} to="#">
                        E-mail and Marketing
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("2")} to="#">
                        Limitation of Liability
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("3")} to="#">
                        Timeliness of Content
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("4")} to="#">
                        Prohibited Uses
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("5")} to="#">
                        Password Security and Notification
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("6")} to="#">
                        Unauthorized Use of Content
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("7")} to="#">
                        System Outages and Incomplete Transmissions
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("8")} to="#">
                        Termination
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("8")} to="#">
                        Waiver
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("10")} to="#">
                        Integration and Severability
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("11")} to="#">
                        Luxury Expense Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__content">
                <h3 className="mt-20">Privacy Policy</h3>
                <p id="1">
                  Please review our Privacy Policy, which is a part of this
                  Agreement and hereby incorporated by reference, to learn about
                  our information collection practices and the measures we take
                  to preserve the privacy and security of your information.
                </p>

                <h4 className="mt-30">E-mail and Marketing</h4>
                <p id="2">
                  Financial professionals who complete the registration process
                  to access the Tera Capital Financial Professional web site are
                  at the same time granting Tera Capital permission to send them
                  e-mail messages for marketing and general communication
                  purposes at the e-mail address they have provided. This
                  service may be discontinued at any time by responding to the
                  email with "Unsubscribe" in the subject line.
                </p>

                <h4 className="mt-30">Limitation of Liability</h4>
                <p>
                  TERA CAPITAL AND ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS,
                  DIRECTORS, EMPLOYEES OR AGENTS WILL NOT BE LIABLE TO YOU OR
                  ANYONE ELSE FOR ANY DAMAGES OF ANY KIND, INCLUDING, BUT NOT
                  LIMITED TO, DIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR
                  INDIRECT DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS,
                  TRADING LOSSES OR DAMAGES THAT RESULT FROM USE OR LOSS OF USE
                  OF THIS WEBSITE), EVEN IF TERA CAPITAL HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES OR LOSSES, INCLUDING, WITHOUT
                  LIMITATION, FROM THE USE OR ATTEMPTED USE OF THIS WEBSITE OR
                  ANOTHER LINKED WEBSITE.{" "}
                </p>
                <p id="3">
                  IF YOU LIVE IN A STATE THAT DOES NOT ALLOW THE LIMITATION OR
                  EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                  OR IF YOU ACCESS THE WEBSITE SOLELY THROUGH AN ARRANGEMENT
                  WITH YOUR EMPLOYER OR PLAN SPONSOR, SOME OR ALL OF THESE
                  LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
                </p>
                <h4 className="mt-30">Timeliness of Content</h4>
                <p id="4">
                  All content on this Website is presented only as of the date
                  published or indicated, and may be superseded by subsequent
                  market events or for other reasons. In addition, you are
                  responsible for setting the cache settings on your browser to
                  ensure you are receiving the most recent data.
                </p>

                <h4 className="mt-30">Prohibited Uses</h4>
                <p>
                  Except as otherwise stated in these Terms or as expressly
                  authorized by Tera Capital in writing, you may not:
                </p>
                <li className="mt-20">
                  Use this Website in any manner that could damage or overburden
                  any Tera Capital server, or any network connected to any Tera
                  Capital server, as all servers have limited capacity and are
                  used by many people;
                </li>
                <li className="mt-20">
                  Use this Website in any manner that would interfere with
                  another party's use of the Website;
                </li>
                <li className="mt-20">
                  Include the term "Tera Capital," or any Tera Capital trademark
                  or executive's name, or any variation of the foregoing, as a
                  meta-tag, hidden textual element;
                </li>
                <li className="mt-20" id="5">
                  Use any robot, spider, intelligent agent, other automatic
                  device, or manual process to search, monitor or copy this
                  Website or the reports, data, information, content, software,
                  products services, or other materials on, generated by or
                  obtained from this Website, whether through links or otherwise
                  (collectively, "Materials"), without Tera Capital's
                  permission, provided that generally available third-party web
                  browsers may be used without such permission; or
                </li>
                <li className="mt-20">
                  Use this Website or the Materials in any manner that could
                  create impression of affiliation, sponsorship or endorsement
                  by Tera Capital.
                </li>

                <h4 className="mt-30">Password Security and Notification</h4>
                <p id="6">
                  Certain parts of the Website are protected by passwords or
                  require a login and are restricted to authorized users only.
                  You may not obtain unauthorized access to such parts of the
                  Website, or to any other protected materials or information,
                  through any means not intentionally made available by Tera
                  Capital for your specific use. If you have a Personal
                  Identification Number (PIN) for access to non-public areas of
                  the Website, you are solely responsible for all activities
                  that occur in connection with your PIN. Accordingly, you
                  should take all reasonable steps to protect the
                  confidentiality of your PIN. Notify Tera Capital immediately
                  if you become aware of any disclosure, loss, theft or
                  unauthorized use of your PIN.
                </p>
                <h4 className="mt-30">Unauthorized Use of Content</h4>
                <p id="7">
                  You agree to defend, indemnify and hold harmless Tera Capital,
                  its affiliates and each of their respective officers,
                  directors, members, partners, managers and employees against
                  any losses, damages, claims, liabilities and costs (including
                  reasonable attorneys’ fees) to the extent resulting from or
                  arising out of any unauthorized or otherwise inappropriate use
                  of any of the content of this Website attributable to you or
                  which occurs through the use of your ID.
                </p>
                <h4 className="mt-30">
                  <b>System Outages and Incomplete Transmissions</b>
                </h4>
                <p id="8">
                  <b>
                    Internet software or transmission problems may produce
                    inaccurate or incomplete copies of information and materials
                    that may be downloaded and displayed on a user's computer.
                    Tera Capital is not liable for any damages, changes, or
                    omissions that occur during transmission of information and
                    materials.
                  </b>
                </p>
                <h4 className="mt-30">Termination</h4>
                <p id="9">
                  Tera Capital may terminate your access to the Website for any
                  reason, without prior notice.
                </p>
                <h4 className="mt-30">Waiver</h4>
                <p id="10">
                  No waiver by Tera Capital of any right under or term or
                  provision of these Terms will be deemed a waiver of any other
                  right, term, or provision of these Terms at the time of such
                  waiver or a waiver of that or any other right, term, or
                  provision of these Terms at any other time.
                </p>
                <h4 className="mt-30">Integration and Severability</h4>
                <p id="11">
                  If any provision of these Terms is deemed unlawful, void, or
                  for any reason unenforceable, then that provision will be
                  deemed severable from these Terms and will not affect the
                  validity and enforceability of the remaining provisions. The
                  preceding Terms of use represent the entire agreement between
                  Tera Capital and the user relating to the subject matter
                  herein. Tera Capital is an equal opportunity employer
                  committed to diversity in the workplace.
                </p>
                <h4 className="mt-30">Luxury Expense Policy</h4>
                <p id="12">
                  With respect to payment of expenses of Tera Capital (the
                  "Fund"), the Fund will only pay or be charged with expenses as
                  authorized in its Limited Partnership Agreement. Under no
                  circumstances will the Fund be charged or pay any expenses
                  related to travel, lodging, entertainment, business meals,
                  transportation, personal telecommunications or meetings or
                  events.
                </p>
                <p>Dated: October 2023.</p>
                <p>
                  © 2023 Tera Power Ltd All rights reserved. TERA CAPITAL, TERA
                  POWER, TERA INVESTMENTS, TERA DATA ENGINE are registered and
                  unregistered trademarks of Tera Power Ltd or its subsidiaries.
                  All other trademarks are those of their respective owners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;
