import { Link } from "react-router-dom";
import React from "react";
import { Email } from "react-obfuscate-email";

const Footer = () => {
  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <>
      <footer
        className="site-footer"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "assets/images/backgrounds/home-footer.jpg"
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-widget__about">
                <Link to="/">
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/images/logo-light.svg"
                    }
                    width="155"
                    alt=""
                  />
                </Link>
                <p>
                  Investing in renewable energy power generators for a cleaner
                  future.
                </p>
                <div className="footer-widget__about-phone">
                  <i className="tera-icon-tech-support"></i>
                  <div className="footer-widget__about-phone-content">
                    <span>Call Us Now</span>
                    <h3>
                      <a href="tel:+35319039642">+353 1 903 9642</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-widget footer-widget__link">
                <h3 className="footer-widget__title">Explore</h3>
                <ul className="list-unstyled footer-widget__link-list">
                  <li>
                    <Link to="our-business">Our Business</Link>
                  </li>
                  <li>
                    <Link to="partnerships">Partnerships</Link>
                  </li>
                  <li>
                    <Link to="technologies">Technologies</Link>
                  </li>
                  {/* <li>
                    <Link to="team">Leadership</Link>
                  </li> */}
                  <li>
                    <Link to="contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-widget__post">
                <h3 className="footer-widget__title">Latest News</h3>
                <ul className="list-unstyled footer-widget__post-list">
                  <li>
                    <img
                      src="assets/images/resources/footer-post-1-1.png"
                      alt=""
                    />
                    <div className="footer-widget__post-list-content">
                      <span>16 Oct, 2020</span>
                      <h3>
                        <Link to="/newsdetails">
                          We’re Providing the Quality Services
                        </Link>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/resources/footer-post-1-2.png"
                      alt=""
                    />
                    <div className="footer-widget__post-list-content">
                      <span>16 Oct, 2020</span>
                      <h3>
                        <Link to="/newsdetails">
                          We’re Providing the Quality Services
                        </Link>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-widget__contact">
                <h3>Contact</h3>
                <ul className="list-unstyled footer-widget__contact-list">
                  <li>
                    <Link to="#">
                      <i className="tera-icon-email1"></i>
                      <Email email="press@teracapital.io" className="text-left">
                        press@teracapital.io
                      </Email>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="tera-icon-clock2"></i>Mon - Sat 8:00 AM -
                      6:00 PM
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="tera-icon-pin1"></i>Suite 1, Charlotte Quay,
                      Grand Canal Dock, Dublin 4, Ireland
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom-footer">
        <div className="container">
          <p className="font12">
            © Copyright {getCurrentYear()} by teracapital.io
          </p>
          <div className="bottom-footer-links font12">
            <Link className="pr-30" to="/code-of-conduct">
              Code of Conduct
            </Link>
            <Link className="pr-30" to="/code-of-ethics">
              Code of Ethics
            </Link>
            <Link className="pr-30" to="/privacy">
              Privacy Policy
            </Link>
            <Link to="/terms">Terms & Conditions</Link>
          </div>
        </div>
      </div>
      <Link
        to="#"
        data-target="html"
        className="scroll-to-target scroll-to-top"
      >
        <i className="fa fa-angle-up"></i>
      </Link>
    </>
  );
};

export default Footer;
