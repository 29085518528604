import { Link } from "react-router-dom";
import React from "react";

function Breadcrumb({ name }) {
  return (
    <>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL +
              "/assets/images/backgrounds/page-header.jpg"
            })`,
          }}
        ></div>
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>/</li>
            <li>
              <Link to="/team"> Team Member</Link>
            </li>
          </ul>
          <h2> {name}</h2>
        </div>
      </section>
    </>
  );
}

export default Breadcrumb;
