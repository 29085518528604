import React from "react";
import { Link } from "react-router-dom";

function WeFinance() {
  return (
    <>
      <section className="service-one service-one__home-two">
        <div className="container">
          <div className="block-title text-center">
            <p>What We Finance</p>
            <h2>Renewable Energy Assets</h2>
          </div>
          <div className="row">
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="000ms"
            >
              <div className="service-one__card">
                <div className="service-one__image">
                  <img src="assets/images/services/solar.jpg" alt="" />
                </div>
                <div className="service-one__content">
                  <h3>
                    <Link to="/our-business">Solar Generators</Link>
                  </h3>
                  <p>
                    We invest in utility-scale power assets (rooftop, ground
                    mounted, agrisolar, and floating)
                  </p>
                  <Link
                    to="/our-business"
                    className="tera-icon-right-arrow service-one__link"
                  ></Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="100ms"
            >
              <div className="service-one__card">
                <div className="service-one__image">
                  <img src="assets/images/services/wind.jpg" alt="" />
                </div>
                <div className="service-one__content">
                  <h3>
                    <Link to="/our-business">Wind Generators</Link>
                  </h3>
                  <p>
                    We invest in onshore wind, offshore wind, and distributed
                    wind systems.
                  </p>
                  <Link
                    to="/our-business"
                    className="tera-icon-right-arrow service-one__link"
                  ></Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="200ms"
            >
              <div className="service-one__card">
                <div className="service-one__image">
                  <img src="assets/images/services/storage.jpg" alt="" />
                </div>
                <div className="service-one__content">
                  <h3>
                    <Link to="/our-business">Energy Storage</Link>
                  </h3>
                  <p>
                    We invest in renewable generators integrating batteries and
                    EV charging stations.
                  </p>
                  <Link
                    to="/our-business"
                    className="tera-icon-right-arrow service-one__link"
                  ></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WeFinance;
