import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../components/Footer/Main";
import Header from "../components/Header/Header";
import Home from "../components/Home/Main";
import Team from "../components/About/Team/Main";
import TeamDetails from "../components/About/TeamDetails/Main";
import Fabian from "../components/About/TeamDetails/Fabian";
import Jessica from "../components/About/TeamDetails/Jessica";
import Ray from "../components/About/TeamDetails/Ray";
// import George from "../components/About/TeamDetails/George";
import OurBusiness from "../components/Pages/OurBusiness/Main";
import Partnerships from "../components/Pages/Partnerships/Main";
import Technology from "../components/Pages/Technologies/Main";
// import FAQ from "../components/Pages/FAQ/Main";
import Contact from "../components/Contact/Main";
import Terms from "../components/Pages/Terms/Main";
import Privacy from "../components/Pages/Privacy/Main";
import Ethics from "../components/Pages/Ethics/Main";
import Conduct from "../components/Pages/Conduct/Main";
import NotFound from "../components/Pages/NotFound/Main";

const Routing = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/team" element={<Team />} />
        <Route path="/team-member" element={<TeamDetails />} />
        <Route path="/team-member/fabian-de-mortier" element={<Fabian />} />
        <Route path="/team-member/jessica-chaijaya" element={<Jessica />} />
        <Route path="/team-member/raymond-alcorn" element={<Ray />} />
        <Route path="/team-member/georgina-foley" element={<George />} /> */}
        <Route path="/our-business" element={<OurBusiness />} />
        <Route path="/partnerships" element={<Partnerships />} />
        <Route path="/technologies" element={<Technology />} />
        {/* <Route path="/faqs" element={<FAQ />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/code-of-ethics" element={<Ethics />} />
        <Route path="/code-of-conduct" element={<Conduct />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
