import React from "react";
import Breadcrumb from "../../Breadcrumb/Main";
import Content from "./Content";
import Seo from "../../../utils/seo";

const ServiceDetails = () => {
  return (
    <>
      <Seo pageTitle="Privacy Policy" id="privacy" />
      <Breadcrumb
        title={"Home"}
        subtitle={"Privacy Policy"}
        common={"Privacy Policy"}
      />
      <Content />
    </>
  );
};

export default ServiceDetails;
