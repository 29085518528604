import { Link } from "react-router-dom";
import React from "react";
import Breadcrumb from "../../Breadcrumb/Main";
import Seo from "../../../utils/seo";
import { Email } from "react-obfuscate-email";

const Service = () => {
  function handleScrollToBusiness() {
    const element = document.getElementById("dobusiness");
    element.scrollIntoView({ behavior: "smooth" });
  }

  function handleScrollToProcurement() {
    const element = document.getElementById("procurement");
    element.scrollIntoView({ behavior: "smooth" });
  }
  function handleScrollToDiversity() {
    const element = document.getElementById("diversity");
    element.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <Seo pageTitle="Our Business" id="business" />
      <Breadcrumb
        title={"Home"}
        subtitle={"Our Business"}
        common={"Our Business"}
      />

      <section className="service-details faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__category">
                  <h3>Quick Menu</h3>
                  <ul className="list-unstyled service-sidebar__category-list">
                    <li>
                      <Link onClick={handleScrollToBusiness} to="#">
                        Deploying Clean Energy Generators Faster
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleScrollToProcurement} to="#">
                        We Care about Communities
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleScrollToDiversity} to="#">
                        Helping Businesses with their Clean Energy Transition
                        Goals
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={handleScrollToEthics} to="#">
                        Standards of supplier ethics & conduct
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="service-sidebar__call">
                  <div
                    className="service-sidebar__call-bg"
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL +
                        // "/assets/images/partnerships/call-us-today.jpg"
                        "/assets/images/partnerships/call-us-today.jpg"
                        // "/assets/images/services/service-sidebar-1-1.jpg"
                      })`,
                    }}
                    // style={{ backgroundImage: `url(${SSBAR1})` }}
                  ></div>
                  <i className="tera-icon-tech-support"></i>
                  <h3>
                    <Link to="#">+353 1 903 9642</Link>
                  </h3>
                  <Email email="info@teracapital.io">info@teracapital.io</Email>
                  <p>
                    Give us a call to get more information on the types of
                    project we invest in.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__content">
                <div id="dobusiness" className="service-details__image">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/our-business/our-business-2.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="mt-30">
                  Deploying Clean Energy Generators Faster
                </h3>
                <p>
                  Tera Capital develops investment strategies to deploy capital
                  in energy generation assets that provide reliable, cheap and
                  sustainable electricity for future generations.
                </p>
                <p>
                  Tera Capital is committed to carefully vet each investment,
                  and ensure we only deploy capital on investment that have the
                  following criteria:
                </p>
                <ul className="list-unstyled about-two__list service-details__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Net positive impact on local ecosystems.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Levelized Cost of Energy competitive with other
                    alternatives.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Net positive impact on local communities.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    The project is contributing to strengthening the resilience
                    of the power grid.
                  </li>
                </ul>
                <div id="procurement" className="service-details__image mt-40">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/our-business/our-business-4.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="mt-30">We Care about Communities</h3>
                <p>
                  Tera Capital focuses on investments that make sense for local
                  communities. Our approach is to consider every facet of an
                  infrastructure project, and make sure it not only contributes
                  additional power capacity to the local grid, but it also
                  provide net positive impact on people who live nearby.
                </p>

                <div id="diversity" className="service-details__image mt-40">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/our-business/our-business-1.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="mt-30">
                  Helping Businesses with their Clean Energy Transition Goals
                </h3>
                <p>
                  Tera Capital works hand-in-hand with businesses that place
                  high value on transitioning towards a more sustainable model.
                  We provide straight forward debt financing and equity
                  participation to relevant projects. We also get involved in
                  structuring complex financial transactions, from securing land
                  rights to negotiating energy offtake aggreements, and anything
                  in between.
                </p>
                <div id="ethics" className="service-details__image mt-40">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partnerships/partnerships-2.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </div>
                {/* <h3 className="mt-30">
                  Standards of supplier ethics & conduct
                </h3>
                <p>
                  Tera Capital’s Supplier Code of Conduct & Ethics outlines the
                  minimum expectations and standards for all Tera Capital
                  Suppliers in relation to human rights, inclusion and
                  diversity, environmental sustainability, integrity and ethics
                  in management practices.
                </p>

                <div className="row">
                  <div className="col-xl-6">
                    <img
                      src="assets/images/services/service-d-1-2.jpg"
                      alt=""
                      className="service-details__content-image img-fluid"
                    />
                  </div>
                  <div className="col-xl-6">
                    <h4>Loan Requirements</h4>
                    <p>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum.
                    </p>
                    <ul className="list-unstyled about-two__list service-details__list">
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Refresing to get such a personal touch
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Duis aute irure dolor in in voluptate
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Velit esse cillum eu fugiat pariatur
                      </li>
                      <li>
                        <i className="fa fa-check-circle"></i>
                        Duis aute irure dolor in in voluptate
                      </li>
                    </ul>
                  </div>
                </div>
                <ul
                  id="accordion"
                  className="mb-0 wow fadeInUp list-unstyled"
                  data-wow-duration="1500ms"
                >
                  <li>
                    <h2
                      className={`para-title ${activeFaq.a && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.a ? { a: false } : { a: true })
                      }
                    >
                      <span
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {activeFaq.a ? (
                          <i className="far fa-minus"></i>
                        ) : (
                          <i className="far fa-plus"></i>
                        )}
                        Need a lower monthly payment plan?
                      </span>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      role="button"
                      aria-labelledby="collapseTwo"
                      data-parent="#accordion"
                    >
                      <p>
                        There are many variations of the have suffer in some fo
                        injected humour, or words believable. Lorem ipsum dolor
                        sit amet, consectetur adip isicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                  </li>
                  <li className="active">
                    <h2
                      className={`para-title ${activeFaq.b && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.b ? { b: false } : { b: true })
                      }
                    >
                      <span
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        {activeFaq.b ? (
                          <i className="far fa-minus"></i>
                        ) : (
                          <i className="far fa-plus"></i>
                        )}
                        Want to study at prestigious university?
                      </span>
                    </h2>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="collapseOne"
                      data-parent="#accordion"
                    >
                      <p>
                        There are many variations of the have suffer in some fo
                        injected humour, or words believable. Lorem ipsum dolor
                        sit amet, consectetur adip isicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h2
                      className={`para-title ${activeFaq.c && "active"}`}
                      onClick={() =>
                        setActiveFaq(activeFaq.c ? { c: false } : { c: true })
                      }
                    >
                      <span
                        className="collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        role="button"
                        aria-controls="collapseThree"
                      >
                        {activeFaq.c ? (
                          <i className="far fa-minus"></i>
                        ) : (
                          <i className="far fa-plus"></i>
                        )}
                        Want to invest and don’t have money?
                      </span>
                    </h2>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="collapseThree"
                      data-parent="#accordion"
                    >
                      <p>
                        There are many variations of the have suffer in some fo
                        injected humour, or words believable. Lorem ipsum dolor
                        sit amet, consectetur adip isicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
