import { Link } from "react-router-dom";
import React from "react";

function Content() {
  function handleScrollTo(item) {
    const element = document.getElementById(item);
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <section className="service-details faq-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__category">
                  <h3>Quick Menu</h3>
                  <ul className="list-unstyled service-sidebar__category-list">
                    <li>
                      <Link onClick={() => handleScrollTo("1")} to="#">
                        Compliance with Laws and Regulations
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("2")} to="#">
                        Conflicts of Interest
                      </Link>
                    </li>

                    <li>
                      <Link onClick={() => handleScrollTo("3")} to="#">
                        Insider Trading and Personal Trading
                      </Link>
                    </li>

                    <li>
                      <Link onClick={() => handleScrollTo("4")} to="#">
                        Gifts and Entertainment
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("5")} to="#">
                        Corporate Opportunities
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("6")} to="#">
                        Competition and Fair Dealing
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("7")} to="#">
                        Confidentiality
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("8")} to="#">
                        Reporting Any Illegal or Unethical Behavior
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("9")} to="#">
                        Protection and Proper Use of Tera Capital Assets
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("10")} to="#">
                        Bribery and Corruption
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("11")} to="#">
                        Equal Employment Opportunity and Harassment
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => handleScrollTo("12")} to="#">
                        Recordkeeping
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__content">
                <h3 className="mt-20">Code of Business Conduct</h3>
                <p id="1">
                  This global Code of Business Conduct and Ethics (“Code”)
                  governs the general commitment by Tera Capital and its
                  subsidiaries (collectively, “Tera Capital”) to conduct its
                  business activities in the highest ethical and professional
                  manner and to put client interests first. Tera Capital’s
                  reputation for integrity is one of its most important assets
                  and is instrumental to its business success. While this Code
                  covers a wide range of business activities, practices, and
                  procedures, it does not cover every issue that may arise in
                  the course of Tera Capital’s many business activities. Rather,
                  it sets out basic principles designed to guide Tera Capital’s
                  employees and directors. Consultants and contingent, contract,
                  or temporary workers are expected to comply with the
                  principles of this Code and policies applicable to their
                  location, function, and status. Every Tera Capital employee
                  and director — whatever his or her position — is responsible
                  for upholding high ethical and professional standards and must
                  seek to avoid even the appearance of improper behavior. Any
                  violation of this Code may result in disciplinary action to
                  the extent permitted by applicable law.
                </p>

                <h4 className="mt-30">Compliance with Laws and Regulations</h4>
                <li className="mt-20" id="2">
                  Tera Capital’s global business activities are subject to
                  extensive governmental regulation and oversight and it is
                  critical that Tera Capital and its employees comply with
                  applicable laws, rules, and regulations, including those
                  relating to insider trading.
                </li>
                <h4 className="mt-30">Conflicts of Interest</h4>
                <p>
                  Conflicts of interest may arise when a person’s private
                  interest interferes, or appears to interfere, with the
                  interests of Tera Capital, or where the interests of an
                  employee or the firm are inconsistent with those of a client
                  or potential client, resulting in the risk of damage to the
                  interests of Tera Capital or one or more of its clients. A
                  conflict may arise, for example, if an employee takes an
                  action or has an interest that could appear to make it
                  difficult for the employee to conduct the employee’s
                  responsibilities to Tera Capital and/or the client objectively
                  and effectively, or if such employee or any person associated
                  with the employee, including but not limited to members of the
                  employee’s family or household, receives an improper personal
                  benefit, such as money or a loan, as a result of the
                  individual’s position at Tera Capital. Tera Capital has
                  adopted policies, procedures, and controls designed to manage
                  conflicts of interest. Employees are required to comply with
                  these and other compliance related policies, procedures, and
                  controls and to help mitigate potential conflicts of interest
                  by adhering to the following standard of conduct:
                </p>
                <li className="mt-20">
                  Act solely in the best interests of clients;
                </li>
                <li className="mt-20">
                  Uphold Tera Capital’s high ethical and professional standards;
                </li>
                <li className="mt-20">
                  Identify, report, and manage actual, apparent, or potential
                  conflicts of interest;
                </li>
                <li className="mt-20">
                  Make full and fair disclosure of any conflicts of interests,
                  as may be required.
                </li>
                <p id="3">
                  Conflicts of interest may not always be clear-cut and it is
                  not possible to describe every situation in which a conflict
                  of interest may arise – any question with respect to whether a
                  conflict of interest exists, together with any actual or
                  potential conflict of interest, should be directed to
                  managers.
                </p>
                <h4 className="mt-30">Insider Trading and Personal Trading</h4>
                <p id="4">
                  Employees and directors who have access to confidential
                  information about Tera Capital, its clients, or issuers in
                  which it invests client assets, are prohibited from using or
                  sharing that information for security trading purposes or for
                  any other purpose except in the proper conduct of our
                  business. All non-public information about Tera Capital or any
                  of our clients or issuers should be considered "confidential
                  information." Use of material, non-public information in
                  connection with any investment decision or recommendation or
                  to “tip” others who might make an investment decision on the
                  basis of this information is unethical and illegal and could
                  result in civil and/or criminal penalties.
                </p>
                <h4 className="mt-30">Gifts and Entertainment</h4>
                <p id="5">
                  Employees must act in the best interests of our clients and
                  consider the reputation of Tera Capital when receiving or
                  providing any gift or entertainment. Employees are prohibited
                  from offering, promising, giving or receiving, or authorizing
                  others to offer, promise, give or receive anything of value,
                  either directly or indirectly, to any party in order to
                  improperly obtain or retain business, or to otherwise gain an
                  improper business advantage. In addition, strict laws govern
                  the provision of gifts and entertainment, including meals,
                  transportation, and lodging, to public officials. Employees
                  are prohibited from providing gifts or anything of value to
                  public officials or their employees or family members in
                  connection with Tera Capital's business for the purpose of
                  obtaining or retaining business or a business advantage.
                </p>

                <h4 className="mt-30">Corporate Opportunities</h4>
                <p>Employees and directors:</p>
                <li className="mt-20">
                  are prohibited from taking personal opportunities for
                  themselves that are discovered through the use of corporate
                  property, information, or position without the consent of
                  Management;
                </li>
                <li className="mt-20">
                  are prohibited from using corporate property, information, or
                  position for improper personal gain;
                </li>
                <li className="mt-20" id="6">
                  may not compete with Tera Capital either directly or
                  indirectly;
                </li>
                <li className="mt-20">
                  owe a duty to Tera Capital to advance its legitimate interests
                  when the opportunity to do so arises.
                </li>
                <h4 className="mt-30">Competition and Fair Dealing</h4>
                <p>
                  Tera Capital seeks to outperform its competition fairly and
                  honestly by seeking competitive advantage through superior
                  performance; Tera Capital does not engage in illegal or
                  unethical business practices. Tera Capital and its employees
                  and directors should endeavor to respect the rights of, and
                  deal fairly with, Tera Capital’s clients, vendors, and
                  competitors. Specifically, the following conduct is
                  prohibited:
                </p>
                <li className="mt-20">
                  misappropriating proprietary information;
                </li>
                <li className="mt-20">
                  possessing trade secret information obtained without the
                  owner’s consent;
                </li>

                <li className="mt-20" id="7">
                  inducing disclosure of proprietary information or trade secret
                  information by past or present employees of other companies;
                  and
                </li>

                <li className="mt-20">
                  taking unfair advantage of anyone through manipulation,
                  concealment, abuse of privileged information,
                  misrepresentation of material facts, or any other intentional
                  unfair-dealing practice.
                </li>
                <h4 className="mt-30">Confidentiality</h4>
                <p id="8">
                  Tera Capital’s employees and directors have an obligation of
                  confidentiality to Tera Capital and its clients. Confidential
                  information includes non-public information that might be of
                  use to competitors or that might harm Tera Capital or its
                  clients, if disclosed, and non-public information that clients
                  and other parties have entrusted to Tera Capital. The
                  obligation to preserve confidential information continues even
                  after employment ends. This obligation does not limit
                  employees from reporting possible violations of law or
                  regulation to a regulator or from making disclosures under
                  whistleblower provisions, as discussed in greater detail in
                  the Global Policy for Reporting Illegal or Unethical Conduct
                  and relevant confidentiality policies and agreements.
                </p>
                <h4 className="mt-30">
                  Reporting Any Illegal or Unethical Behavior
                </h4>
                <p id="9">
                  Every employee is required to report any illegal or unethical
                  conduct about which they become aware, including those
                  concerning accounting or auditing matters. Tera Capital will
                  not retaliate or discriminate against any employee because of
                  a good faith report. Employees have the right to report
                  directly to a regulator and may do so anonymously; employees
                  may provide protected disclosures under whistleblower laws and
                  cooperate voluntarily with regulators, in each case without
                  fear of retaliation by Tera Capital.{" "}
                </p>
                <h4 className="mt-30">
                  Protection and Proper Use of Tera Capital Assets
                </h4>
                <p id="10">
                  Employees and directors should make every effort to protect
                  Tera Capital’s assets and use them efficiently. This
                  obligation extends to Tera Capital’s proprietary information,
                  including intellectual property such as trade secrets,
                  patents, trademarks, and copyrights, as well as business,
                  marketing and service plans, engineering and manufacturing
                  ideas, systems, software programs, designs, databases,
                  records, salary information, and any unpublished financial
                  data and reports. Unauthorized use or distribution of
                  proprietary information constitutes a violation of Tera
                  Capital policy and could result in civil and/or criminal
                  penalties.{" "}
                </p>
                <h4 className="mt-30">Bribery and Corruption</h4>
                <p id="11">
                  Tera Capital employees and directors are prohibited from
                  making payments or offering or giving anything of value,
                  directly or indirectly, to public officials of any country, or
                  to persons in the private sector, if the intent is to
                  influence such persons to perform (or reward them for
                  performing) a relevant function or activity improperly or to
                  obtain or retain business or an advantage in the course of
                  business conduct.
                </p>
                <h4 className="mt-30">
                  Equal Employment Opportunity and Harassment
                </h4>
                <p id="12">
                  Tera Capital is firmly committed to providing equal
                  opportunity in all aspects of employment and will not tolerate
                  any illegal discrimination or harassment of any kind. In
                  particular, it is Tera Capital’s policy to afford equal
                  opportunity to all qualified applicants and existing employees
                  without regard to race, ethnicity, religion, color, national
                  origin, sex, pregnancy status, pregnancy-related medical
                  conditions, gender, gender identity or expression, sexual
                  orientation, age, ancestry, physical or mental disability,
                  familial or marital status, political affiliation, citizenship
                  status, genetic information, or protected veteran or military
                  status or any other basis that would be in violation of any
                  applicable ordinance or law. In addition, Tera Capital will
                  not tolerate harassment, bias, or other inappropriate conduct
                  on the basis of any of the above protected categories.
                </p>
                <h4 className="mt-30">Recordkeeping</h4>
                <p id="13">
                  Tera Capital requires honest and accurate recording and
                  reporting of information in order to conduct its business and
                  to make responsible business decisions. Tera Capital, as a
                  financial services provider, is subject to extensive
                  regulations regarding maintenance and retention of books and
                  records. Tera Capital’s books, records, accounts, and
                  financial statements must be maintained in reasonable detail,
                  must appropriately reflect Tera Capital’s transactions, and
                  must conform both to applicable legal requirements and to Tera
                  Capital’s system of internal controls.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;
