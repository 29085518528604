import React from "react";
import Breadcrumb from "../../Breadcrumb/Main";
import Content from "./Content";
import Seo from "../../../utils/seo";

const ServiceDetails = () => {
  return (
    <>
      <Seo pageTitle="Code of Ethics" id="ethics" />
      <Breadcrumb
        title={"Home"}
        subtitle={"Code of Ethics"}
        common={"Code of Ethics"}
      />
      <Content />
    </>
  );
};

export default ServiceDetails;
