import React from "react";
import Slider from "./Slider";
import WeAre from "./WeAre";
import Services from "./Services";
import DataScience from "./DataScience";
import Ethics from "./Ethics";
import WeFinance from "./WeFinance";
import WeServe from "./WeServe";
import Expertise from "./Expertise";
import Partners from "./Partners";
import Seo from "../../utils/seo";

const Home = () => {
  return (
    <>
      <Seo pageTitle="Home" id="home" />
      <Slider />
      <Services />
      <WeAre />
      <WeFinance />
      <WeServe />
      <DataScience />
      <Expertise />
      <Ethics />
      <Partners />
    </>
  );
};

export default Home;
