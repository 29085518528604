import React from "react";

const Service = () => {
  return (
    <>
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 text-center">
              <div className="img-fluid">
                <img
                  src="/assets/images/resources/not-found.svg"
                  alt="Page not found illustration"
                  layout="fill"
                  width="300px"
                />
              </div>
              <div className="notfound_text">
                <h1>Page Not Found</h1>
              </div>
              <p>We can&apos;t seem to find the page you&apos;re looking for</p>

              <a className="btn btn_error btn-thm" href="/">
                Back To Home
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
