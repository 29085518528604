import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";

function Partners() {
  return (
    <>
      <div className="client-carousel pt-120 pb-120 client-carousel__has-border-top">
        <div className="container">
          <Swiper
            className="thm-swiper__slider swiper-container"
            modules={[Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 1500 }}
            breakpoints={{
              376: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              576: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              992: {
                spaceBetween: 30,
                slidesPerView: 4,
              },
              1200: {
                spaceBetween: 30,
                slidesPerView: 5,
              },
            }}
          >
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partners/hsbc.svg"
                    }
                    alt="hsbc"
                    width="130px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/partners/bnp.svg"
                    }
                    alt="bnp"
                    width="150px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partners/jpmorgan.svg"
                    }
                    alt="jpmorgan"
                    width="150px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/partners/dbs.svg"
                    }
                    alt="dbs"
                    width="100px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/partners/Santander.svg"
                    }
                    alt="Santander"
                    width="160px"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/partners/sg.svg"
                    }
                    alt="sg"
                    width="150px"
                  />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Partners;
